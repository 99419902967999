@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.result_pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.result_pagination li {
  list-style: none;
}

.result_pagination li a {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0 0 0.5rem;
  padding: 0;
  font-size: medium;
  text-align: center;
  line-height: 2rem;
  border-radius: 0.4rem;
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
  background-color: #fff;
  border: 1px solid #edf4f3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.result_pagination li:not(.active):not(.disable) a:hover,
.result_pagination li.active a,
.result_pagination li.active a:hover {
  background-color: #CC00FF;
  color: #fff;
}

.result_pagination ul li.disable a,
.result_pagination ul li.active a {
  cursor: default;
}